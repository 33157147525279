import React, { Component } from "react"
import { Container, Row, Col, Card, CardTitle, CardText } from "reactstrap"

class About extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h1>About Moodlet</h1>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card body outline color="info" className="text-center">
              <CardTitle tag="strong">Offline only</CardTitle>
              <CardText>
                All data is stored offline only. We do not have any access to
                your data at all.
              </CardText>
            </Card>
          </Col>
          <Col md={4}>
            <Card body outline color="info" className="text-center">
              <CardTitle tag="strong">Free</CardTitle>
              <CardText>Free to use, and no ads.</CardText>
            </Card>
          </Col>
          <Col md={4}>
            <Card body outline color="info" className="text-center">
              <CardTitle tag="strong">Android</CardTitle>
              <CardText>Currently, it's Android only.</CardText>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default About
