import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"

class Home extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h1>Moodlet</h1>
            Free app to keep track of your current mood and log possible reasons
            for why you are feeling a certain way.
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Home
