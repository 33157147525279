import React, { Component } from "react"
import { Route } from "react-router-dom"
import Home from "./containers/Home"
import PrivacyPolicy from "./containers/privacy/PrivacyPolicy"
import TermsConditions from "./containers/privacy/TermsConditions"
import HSPrivacyPolicy from "./containers/privacy/HSPrivacyPolicy"
import HSTermsConditions from "./containers/privacy/HSTermsConditions"
import { Container, Row } from "reactstrap"
import Navigation from "./containers/navigation/Navigation"
import "./App.css"
import About from "./containers/about/About"

class App extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row>
          <main className="main-content col-lg-12 col-md-12 col-sm-12 p-0">
            <Navigation />
            <div className="main-content-container container-fluid px-4 pad-top">
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/privacy.html" component={PrivacyPolicy} />
              <Route exact path="/terms" component={TermsConditions} />
              <Route
                exact
                path="/homeset/privacy"
                component={HSPrivacyPolicy}
              />
              <Route
                exact
                path="/homeset/privacy.html"
                component={HSPrivacyPolicy}
              />
              <Route
                exact
                path="/homeset/terms"
                component={HSTermsConditions}
              />
              <Route
                exact
                path="/homeset/terms.html"
                component={HSTermsConditions}
              />
            </div>
          </main>
        </Row>
      </Container>
    )
  }
}

export default App
