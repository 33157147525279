import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem
} from "reactstrap"

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  _routePrefix = path => {
    if (this.props.location.pathname.includes("homeset")) {
      return `/homeset/${path}`
    }
    return path
  }

  render() {
    console.log(this.props.location.pathname)
    return (
      <Navbar color="dark" dark expand="md">
        <NavbarBrand tag={Link} to={this._routePrefix("privacy")}>
          {this.props.location.pathname.includes("homeset")
            ? "Homeset"
            : "Moodlet"}
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/* <NavItem>
              <NavLink tag={Link} to="/about">
                About
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink tag={Link} to={this._routePrefix("privacy")}>
                Privacy Policy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={this._routePrefix("terms")}>
                Terms and Conditions
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default withRouter(Navigation)
