import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardLink,
  CardBody
} from "reactstrap"
import "./style.css"

class HSPrivacyPolicy extends Component {
  render() {
    return (
      <Container>
        <Row>
          <h2>Homeset Privacy Policy</h2>
          <Card className="no-border">
            <CardBody>
              <CardText>
                This page is used to inform visitors regarding our policies with
                the collection, use, and disclosure of Personal Information if
                anyone decided to use our Service.
              </CardText>
              <CardText>
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </CardText>
              <CardText>
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which is accessible at Homeset
                unless otherwise defined in this Privacy Policy.
              </CardText>
            </CardBody>
          </Card>
          <h3>Information Collection and Use</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to name, email, location.
                The information that we request will be retained by us and used
                as described in this privacy policy.
              </CardText>
              <CardText>
                The app does use third party services that may collect
                information used to identify you.
              </CardText>
            </CardBody>
          </Card>

          <Card className="no-border">
            <CardBody>
              <CardText>
                Links to privacy policy of third party service providers used by
                the app:
              </CardText>
              <CardLink
                href="https://www.google.com/policies/privacy/"
                rel="noopener noreferrer"
              >
                Google Play Services
              </CardLink>
              <CardLink
                href="https://firebase.google.com/policies/analytics"
                rel="noopener noreferrer"
              >
                Firebase Analytics
              </CardLink>
            </CardBody>
          </Card>

          <Row>
            <Col>
              <h3>Log Data</h3>
              <Card className="no-border">
                <CardBody>
                  <CardText>
                    We want to inform you that whenever you use our Service, in
                    a case of an error in the app we collect data and
                    information (through third party products) on your phone
                    called Log Data. This Log Data may include information such
                    as your device Internet Protocol (“IP”) address, device
                    name, operating system version, the configuration of the app
                    when utilizing our Service, the time and date of your use of
                    the Service, and other statistics.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <h3>Cookies</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory.
              </CardText>
              <CardText>
                This Service does not use these “cookies” explicitly. However,
                the app may use third party code and libraries that use
                “cookies” to collect information and improve their services. You
                have the option to either accept or refuse these cookies and
                know when a cookie is being sent to your device. If you choose
                to refuse our cookies, you may not be able to use some portions
                of this Service.
              </CardText>
            </CardBody>
          </Card>
          <h3>Service Providers</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                We may employ third-party companies and individuals due to the
                following reasons:
              </CardText>
              <CardText>
                <ul>
                  <li>To facilitate our Service;</li>
                  <li>To provide the Service on our behalf;</li>
                  <li>To perform Service-related services; or</li>
                  <li>To assist us in analyzing how our Service is used.</li>
                </ul>
              </CardText>
              <CardText>
                We want to inform users of this Service that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </CardText>
            </CardBody>
          </Card>

          <h3>Security</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </CardText>
            </CardBody>
          </Card>
          <h3>Links to Other Sites</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we h3ly
                advise you to review the Privacy Policy of these websites. We
                have no control over and assume no responsibility for the
                content, privacy policies, or practices of any third-party sites
                or services.
              </CardText>
            </CardBody>
          </Card>
          <h3>Children’s Privacy</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                These Services do not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                children under 13. In the case we discover that a child under 13
                has provided us with personal information, we immediately delete
                this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal
                information, please contact us so that we will be able to do
                necessary actions.
              </CardText>
            </CardBody>
          </Card>
          <h3>Changes to This Privacy Policy</h3>
          <Card className="no-border">
            <CardBody>
              <CardText>
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
              </CardText>
            </CardBody>
          </Card>
          <Row>
            <Col>
              <h3>Contact Us</h3>
              <Card className="no-border">
                <CardBody>
                  <CardText>
                    If you have any questions or suggestions about our Privacy
                    Policy, do not hesitate to contact us at hawry@hawry.net
                  </CardText>
                  <CardText>
                    This privacy policy page was created at{" "}
                    <a
                      href="https://privacypolicytemplate.net"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacypolicytemplate.net
                    </a>{" "}
                    and modified/generated by{" "}
                    <a
                      href="https://app-privacy-policy-generator.firebaseapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Privacy Policy Generator
                    </a>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Row>
      </Container>
    )
  }
}

export default HSPrivacyPolicy
